.footer-dark {
  padding:12px 0;
  color:#f0f9ff;
  background-color:#221C35;
  color: #E5E1E6;
  bottom: 0px;
  position: absolute;
  left:0;
  bottom:0;
  right:0;
}

.footer-container {
  text-align: center;
}

.footer-dark h3 {
  margin-top:12;
  margin-bottom:0px;
  font-weight:bold;
  font-size:16px;
}

.footer-dark ul {
  padding:0;
  list-style:none;
  line-height:1.6;
  font-size:14px;
  margin-bottom:0;
}

.footer-dark ul a {
  color:inherit;
  text-decoration:none;
  opacity:0.6;
}

.footer-dark ul a:hover {
  opacity:0.8;
}

.footer-dark .item.text {
  margin-bottom:12px;
}

.footer-dark .copyright {
  text-align:center;
  padding-top:24px;
  opacity:0.3;
  font-size:13px;
  margin-bottom:0;
}

.footer-dark .sign {
  text-align:center;
  padding-top:0px;
  opacity:0.3;
  font-size:13px;
  margin-bottom:0;
}