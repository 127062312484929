.login-dark {
    background-color: #E5E1E6;
    color: #221C35;
}

.ant {
    font-family: Inconsolata regular-Bold;
}

.ant-pro-form-login-top {
    color: #221C35;
}

.ant-btn {
    background-color: #4A25AA;
}